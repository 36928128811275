import { useAlert } from "@providers/AlertProvider/hooks/useAlert";
import { useFetchLiveBroadcasts } from "@data/broadcasts/useFetchLiveBroadcasts";
import { useGetNavbarBroadcastSeries } from "./useGetNavbarBroadcastSeries";
import { useCallback, useMemo } from "react";
import { useGetSettings } from "@data/sanity/getSettings/useGetSettings";
import { NavbarChildItem, NavbarTopLevelItem } from "@data/sanity/getSettings/schemas/NavbarSchema";
import { useGameId } from "@providers/GameIdProvider/useGameId";

export const useLiveNavItem = (): {
  navbarItem: NavbarTopLevelItem | null;
} => {
  const { gameId } = useGameId();
  const { data: settingsData } = useGetSettings();
  const { showInfoAlert } = useAlert();
  const onLiveBroadcastStart = useCallback(() => {
    showInfoAlert("Live stream just started!");
  }, [showInfoAlert]);
  const { data: liveBroadcasts } = useFetchLiveBroadcasts({
    onLiveBroadcastStart,
    enabled: settingsData?.settings?.enableAutomaticLiveState,
  });

  const broadcastSeries = useGetNavbarBroadcastSeries({ liveBroadcasts });

  const navbarLiveChildren: NavbarChildItem[] = useMemo(() => {
    if (gameId !== "cs") return []; // Only CS

    if (!broadcastSeries || !settingsData?.settings?.enableAutomaticLiveState) return [];

    return Object.values(broadcastSeries).map((broadcast) => ({
      _type: "navbarItem",
      _key: broadcast.id,
      title: broadcast.title,
      route: `/live/${broadcast.slug}`,
    }));
  }, [broadcastSeries, settingsData?.settings?.enableAutomaticLiveState, gameId]);

  return {
    navbarItem: navbarLiveChildren?.length
      ? {
          _type: "navbarItem",
          _key: "live",
          title: "Live",
          children: navbarLiveChildren,
        }
      : null,
  };
};
