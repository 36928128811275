export const ssrRoutesPatterns = {
  local: ["/article*", "/cs/news", "/cs/news/*", "/dota/news", "/dota/news/*", "/rl/news*", "/quiz/*", "/gaming*"],
  development: [
    "/article*",
    "/cs/news",
    "/cs/news/*",
    "/dota/news",
    "/dota/news/*",
    "/rl/news*",
    "/quiz/*",
    "/gaming*",
  ],
  production: ["/article*", "/cs/news", "/cs/news/*", "/dota/news", "/dota/news/*", "/rl/news*", "/quiz/*", "/gaming*"],
};
